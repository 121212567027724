import React, { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { gradeMapping } from "../../../../../boilerplate/list.json";
import { Radar } from "react-chartjs-2";

export default function StartupScore({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
}) {
  const [startupScores, setStartupScores] = useState([]);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [startupScoreError, setStartupScoreError] = useState("");
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});
  const [expandedScores, setExpandedScores] = useState({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.startupscores) {
      setStartupScores([...companyInfo.aiGenerations.startupscores].reverse());
    }
  }, [companyInfo]);

  const getGradeFromScore = (score) => {
    const roundedScore = Math.round(score * 10) / 10; // Round to nearest tenth
    return gradeMapping[roundedScore] || "N/A";
  };

  const getRadarOptions = () => ({
    maintainAspectRatio: true,
    scale: {
      ticks: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 10,
        stepSize: 2,
      },
      grid: {
        color: "#ddd",
      },
      angleLines: {
        color: "#ccc",
      },
      pointLabels: {
        display: true,
        font: {
          size: 14,
        },
      },
    },
  });

  const getRadarData = (startupScore) => {
    const categories = Object.keys(startupScore).filter(
      (category) => category.toLowerCase() !== "overall" && category !== "_id",
    );

    return {
      labels: categories,
      datasets: [
        {
          label: "Startup Score",
          data: categories.map(
            (category) => startupScore[category]?.Score || 0,
          ),
          backgroundColor: "rgba(34, 202, 236, 0.2)",
          borderColor: "rgba(34, 202, 236, 1)",
          borderWidth: 2,
          pointBackgroundColor: "rgba(34, 202, 236, 1)",
        },
      ],
    };
  };

  const getComparisonRadarData = (lastTwoScores) => {
    const categories = Object.keys(lastTwoScores[0].startupscore).filter(
      (category) => category.toLowerCase() !== "overall" && category !== "_id",
    );

    return {
      labels: categories,
      datasets: lastTwoScores.map((score, index) => ({
        label: `Startup Score ${index + 1}`,
        data: categories.map(
          (category) => score.startupscore[category]?.Score || 0,
        ),
        backgroundColor:
          index === 0 ? "rgba(34, 202, 236, 0.2)" : "rgba(255, 99, 132, 0.2)",
        borderColor:
          index === 0 ? "rgba(34, 202, 236, 1)" : "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        pointBackgroundColor:
          index === 0 ? "rgba(34, 202, 236, 1)" : "rgba(255, 99, 132, 1)",
      })),
    };
  };

  const renderComparisonChart = () => {
    if (startupScores.length < 2) return null;

    // Get the last two scores
    const lastTwoScores = startupScores.slice(0, 2);

    return (
      <div
        style={{
          width: "100%",
          margin: "0 auto",
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <Radar
          data={getComparisonRadarData(lastTwoScores)}
          options={getRadarOptions()}
        />
      </div>
    );
  };

  const renderRadarChart = (startupScore) => (
    <div style={{ width: "100%", margin: "20px auto" }}>
      <Radar data={getRadarData(startupScore)} options={getRadarOptions()} />
    </div>
  );

  const handleStartupScore = async () => {
    const { pitchdeckUrl } = companyInfo;
    const companyId = companyInfo.applicant_company;
    const opportunityId = opportunityInfo._id;

    // Check if both application data and pitch deck URL are missing
    if (!applicationObject && !pitchdeckUrl) {
      setStartupScoreError("Application Info and Pitchdeck URL are missing.");
      return;
    }

    // Check if enough credits are available
    if (opportunityInfo.credits < 1) {
      setStartupScoreError(
        "You do not have enough credits to generate a startup score.",
      );
      return;
    }

    setIsSummarizing(true);
    setStartupScoreError(""); // Clear any previous errors

    try {
      // Prepare the request body with valid answers and pitchdeck URL
      const requestBody = {};

      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      if (pitchdeckUrl) {
        requestBody.pitchdeckUrl = pitchdeckUrl;
      }

      // Make the fetch request
      const response = await fetch(
        `/fetch_company_score?company_id=${companyId}&opportunity_id=${opportunityId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        },
      );

      // Check for specific 404 status and handle it
      if (response.status === 404) {
        setStartupScoreError(
          "Pitch deck or necessary information is missing. Please upload a pitch deck.",
        );
        throw new Error("Pitch deck or necessary information is missing.");
      }

      // Handle other unsuccessful responses
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Parse the response data
      const data = await response.json();
      console.log("Response from server:", data);

      // Deduct a credit after successful response
      handleCreditChange(-1);

      // Construct the new startup score
      const newStartupScore = {
        startupscore: data.analysis.startupscore,
        created_on: data.analysis.created_on,
        credits_used: data.analysis.credits_used,
      };

      // Update company data with the new startup score
      const updatedScores = [
        newStartupScore,
        ...(companyInfo.aiGenerations?.startupscores || []), // Preserve previous scores
      ];

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          startupscores: updatedScores,
        },
      };

      updateCompanyData(companyId, updates);

      // Reverse the order when setting the state for display
      setStartupScores([...updatedScores].reverse());
      setIsNewlyGenerated(true);
      setAnimationComplete({});
      setExpandedScores((prev) => ({
        ...prev,
        0: true, // Expand the most recent score, which is now at index 0
      }));
    } catch (error) {
      console.error("Error fetching company score:", error);
      setStartupScoreError("Error fetching company score.");
    } finally {
      setIsSummarizing(false); // Reset the loading state
    }
  };

  const handleAnimationComplete = (category, scoreIndex) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [`${scoreIndex}-${category}`]: true,
    }));

    const allComplete = Object.values(animationComplete).every(
      (value) => value,
    );
    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, category, scoreIndex) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(category, scoreIndex)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  const toggleScoreExpansion = (index) => {
    setExpandedScores((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderScoreDetails = (score, index) => {
    if (
      !score ||
      !score.startupscore ||
      Object.keys(score.startupscore).length === 0
    )
      return null;

    const overallCategory = "Overall";
    const categories = Object.keys(score.startupscore).filter(
      (category) => category.toLowerCase() !== "overall" && category !== "_id",
    );

    return (
      <div className="report-container" key={index}>
        <div
          className="overall-summary"
          onClick={() => toggleScoreExpansion(index)}
        >
          <h2 className="overall-score">
            Startup Score {startupScores.length - index}:{" "}
            <span className="overall-score">
              {getGradeFromScore(score.startupscore[overallCategory]?.Score)}
            </span>
            {expandedScores[index] ? <ExpandLess /> : <ExpandMore />}
          </h2>
          <div className="score-details-right">
            <p className="credits-used">Credits Used: {score.credits_used}</p>
            <p className="created-on">
              Date Created: {new Date(score.created_on).toLocaleDateString()}
            </p>
          </div>
        </div>

        {expandedScores[index] && (
          <>
            {renderRadarChart(score.startupscore)}
            <div className="overall-score-container">
              <div
                className="overall-score-bar"
                style={{
                  width: `${
                    (score.startupscore[overallCategory]?.Score || 0) * 10
                  }%`,
                }}
              ></div>
            </div>
            <p className="summary-text">
              {isNewlyGenerated && index === 0
                ? renderTypingAnimation(
                    score.startupscore[overallCategory]?.Reason ||
                      "No overall summary available.",
                    "overall",
                    index,
                  )
                : score.startupscore[overallCategory]?.Reason ||
                  "No overall summary available."}
            </p>

            {categories.map((category, catIndex) => (
              <div key={catIndex} className="category-item">
                <h3 className="category-title">
                  {category}: {score.startupscore[category].Score}
                </h3>
                <div className="score-container">
                  <div
                    className="score-bar"
                    style={{
                      width: `${(score.startupscore[category].Score || 0) * 10}%`,
                    }}
                  ></div>
                </div>
                <p className="category-reason">
                  {isNewlyGenerated && index === 0
                    ? renderTypingAnimation(
                        score.startupscore[category].Reason ||
                          "No reason provided.",
                        category,
                        index,
                      )
                    : score.startupscore[category].Reason ||
                      "No reason provided."}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Startup Score</h4>
      <p className="text-grey text-sm">
        Generate a Startup Score using the patent-pending StartupFuel Scoring
        DiligenceGPT Model.
      </p>
      <div className="flex justify-between">
        <div className="mr-4">
          <button
            onClick={handleStartupScore}
            disabled={isSummarizing}
            className="summarize-button"
          >
            {isSummarizing ? "Generating..." : "Generate Startup Score"}
          </button>
        </div>
      </div>
      {startupScoreError && <p className="error">{startupScoreError}</p>}
      {renderComparisonChart()}
      {startupScores.map((score, index) => renderScoreDetails(score, index))}
    </div>
  );
}
