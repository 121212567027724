import React, { useState } from "react";
import Submenu from "../submenu";
import StartupScore from "./startupScore";
import Tearsheet from "./tearsheet";
import SubReports from "./subReports";
import Summary from "./summary";
import ComingSoon from "./comingSoon";
import DataIntegrityScore from "./dataIntegrity";
import ChatLayout from "../reviewsCardContainer";
import Metrics from "./metrics";

const ReportsMain = ({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
  user,
}) => {
  const menuItems = [
    "Data Integrity",
    "Tear sheet",
    "Deal Brief",
    "Startup Score",
    "Metrics",
  ];
  const [activeItem, setActiveItem] = useState("Data Integrity");

  const renderActiveComponent = () => {
    switch (activeItem) {
      case "Data Integrity":
        return (
          <DataIntegrityScore
            updateCompanyData={updateCompanyData}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Tear sheet":
        return (
          <Tearsheet
            updateCompanyData={updateCompanyData}
            applicationInfo={applicationObject}
            company={companyInfo}
            handleCreditChange={handleCreditChange}
            opportunity_data={opportunityInfo}
          />
        );
      case "Deal Brief":
        return (
          <Summary
            updateCompanyData={updateCompanyData}
            applicationObject={applicationObject}
            companyInfo={companyInfo}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Startup Score":
        return (
          <StartupScore
            updateCompanyData={updateCompanyData}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Metrics":
        return (
          <Metrics
            updateCompanyData={updateCompanyData}
            opportunityInfo={opportunityInfo}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            handleCreditChange={handleCreditChange}
          />
        );
      // case "Idea":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Idea"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Product":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Tech"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Team":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Team"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Marketing":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Marketing"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      // case "Traction":
      //   return (
      //     <SubReports
      //       updateCompanyData={updateCompanyData}
      //       page="Traction"
      //       companyInfo={companyInfo}
      //       applicationObject={applicationObject}
      //       opportunityInfo={opportunityInfo}
      //       handleCreditChange={handleCreditChange}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <ChatLayout
      companyInfo={companyInfo}
      opportunity_data={opportunityInfo}
      updateCompanyData={updateCompanyData}
      applicationObject={applicationObject}
      handleCreditChange={handleCreditChange}
      user={user}
    >
      <div>
        <Submenu
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          menuItems={menuItems}
        />
        <div className="reports-content">{renderActiveComponent()}</div>
      </div>
    </ChatLayout>
  );
};

export default ReportsMain;
