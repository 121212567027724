import React, { useState } from "react";
import classNames from "classnames";
import "./dealInfo.css";
import { Delete, CloudDownload, Visibility } from "@material-ui/icons";
import FileViewerPopup from "../FileViewer";
import {
  document_labels,
  file_type_mapping,
} from "../../../../../boilerplate/list.json";
import AddFiles from "../multipleDocumentUpload"; // Import AddFiles component

const DataRoomComponent = ({
  files,
  getFiles,
  deleteFile,
  companyInfo,
  updateCompanyData,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close

  const handleViewFile = (fileUrl) => {
    setSelectedFile(fileUrl);
  };

  const handleCloseViewer = () => {
    setSelectedFile(null);
  };

  const handleDelete = (fileType) => {
    console.log(`Delete request for file type: ${fileType}`);
    if (window.confirm("Are you sure you want to delete this file?")) {
      deleteFile(fileType);
    }
  };

  const handleDeleteExtractedFile = async (docType) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        const response = await fetch(
          `/delete_extracted_file?fileType=${encodeURIComponent(
            docType,
          )}&company_id=${companyInfo.applicant_company}`, // Construct the API URL
          {
            method: "GET",
          },
        );

        if (response.ok) {
          alert(`File of type "${docType}" has been successfully deleted.`);

          // Clone the current company content to avoid mutating the original state
          const updatedContent = JSON.parse(
            JSON.stringify(companyInfo.content),
          );

          // Remove the file type from `extracted` or `anonymized`
          if (updatedContent.extracted && updatedContent.extracted[docType]) {
            delete updatedContent.extracted[docType];
          } else if (
            updatedContent.anonymized &&
            updatedContent.anonymized[docType]
          ) {
            delete updatedContent.anonymized[docType];
          }

          // Call updateCompanyData with the updated content to reflect the changes
          updateCompanyData(companyInfo.applicant_company, {
            content: updatedContent,
          });
        } else {
          console.error(`Failed to delete document of type "${docType}".`);
          alert(`Failed to delete document of type "${docType}".`);
        }
      } catch (error) {
        console.error(`Error deleting document of type "${docType}":`, error);
        alert(`An error occurred while trying to delete the document.`);
      }
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  // Function to get the file operation type based on company content
  const getFileOperationType = (fileType) => {
    if (companyInfo.content?.extracted?.[fileType]) {
      return "Extract";
    }
    if (companyInfo.content?.anonymized?.[fileType]) {
      return "Anonymize";
    }
    return "File";
  };

  // Function to get the original file type name from file type mapping
  const getMappedFileTypeName = (fileType) => {
    return (
      Object.keys(file_type_mapping).find(
        (key) => file_type_mapping[key] === fileType,
      ) || fileType
    );
  };

  return (
    <div>
      <h4>Data Room</h4>
      <p className="text-grey text-sm">View/Modify Company Files</p>
      <div>
        <button className="add-files-button" onClick={handleOpenModal}>
          Add Files
        </button>

        {/* Uploaded Files Section */}
        <h5 className="section-title">Uploaded Files</h5>
        <div className="review-content-card">
          {files.length === 0 ? (
            <p className="text-grey text-sm">No files uploaded.</p>
          ) : (
            <table className="table-fixed w-100">
              <thead>
                <tr className="border-gray border-b">
                  <th className="p-2 text-left">File Type</th>
                  <th className="p-2 text-left">File Size</th>
                  <th className="p-2 text-left">Added On</th>
                  <th className="p-2 text-left">Operation</th>
                  <th className="p-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => {
                  const isLastItem = index === files.length - 1;
                  const originalFileType = getMappedFileTypeName(
                    file.file_type,
                  );
                  const operationType = getFileOperationType(file.file_type);

                  return (
                    <tr
                      key={index}
                      className={classNames("border-gray border-t", {
                        "border-b": !isLastItem,
                      })}
                    >
                      <td className="py-4 text-sm">{originalFileType}</td>
                      <td className="py-4 text-sm">{file.file_size}</td>
                      <td className="py-4 text-sm">
                        {new Date(file.time_stamp).toLocaleString()}
                      </td>
                      <td className="py-4 text-sm">
                        <span
                          className={`badge badge-${operationType.toLowerCase()}`}
                        >
                          {operationType}
                        </span>
                      </td>
                      <td className="py-4 text-sm">
                        {operationType === "File" && (
                          <>
                            <button
                              onClick={() => handleViewFile(file.file_url)}
                              className="data-room-icon-button"
                            >
                              <Visibility />
                            </button>
                            <a
                              href={file.file_url}
                              download
                              className="data-room-icon-button"
                            >
                              <CloudDownload />
                            </a>
                          </>
                        )}
                        <button
                          onClick={() => handleDelete(file.file_type)}
                          className="data-room-icon-button"
                        >
                          <Delete />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {/* Company Extracted & Anonymized Documents Section */}
        {(companyInfo.content?.extracted &&
          Object.keys(companyInfo.content.extracted).length > 0) ||
        (companyInfo.content?.anonymized &&
          Object.keys(companyInfo.content.anonymized).length > 0) ? (
          <div>
            <h5 className="section-title">Extracted & Anonymized Documents</h5>
            <div className="review-content-card">
              <table className="table-fixed w-100">
                <thead>
                  <tr className="border-gray border-b">
                    <th className="p-2 text-left">Document Type</th>
                    <th className="p-2 text-left">Operation</th>
                    <th className="p-2 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Display Extracted Documents */}
                  {Object.entries(companyInfo.content?.extracted || {}).map(
                    ([docType], index) => (
                      <tr
                        key={`extracted-${index}`}
                        className="border-gray border-t"
                      >
                        <td className="py-4 text-sm">
                          {getMappedFileTypeName(docType)}
                        </td>
                        <td className="py-4 text-sm">
                          <span className="badge badge-extract">Extract</span>
                        </td>
                        <td className="py-4 text-sm">
                          <button
                            onClick={() => handleDeleteExtractedFile(docType)}
                            className="data-room-icon-button"
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    ),
                  )}

                  {/* Display Anonymized Documents */}
                  {Object.entries(companyInfo.content?.anonymized || {}).map(
                    ([docType], index) => (
                      <tr
                        key={`anonymized-${index}`}
                        className="border-gray border-t"
                      >
                        <td className="py-4 text-sm">
                          {getMappedFileTypeName(docType)}
                        </td>
                        <td className="py-4 text-sm">
                          <span className="badge badge-anonymize">
                            Anonymize
                          </span>
                        </td>
                        <td className="py-4 text-sm">
                          <button
                            onClick={() => handleDeleteExtractedFile(docType)}
                            className="data-room-icon-button"
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
      {selectedFile && (
        <FileViewerPopup fileUrl={selectedFile} onClose={handleCloseViewer} />
      )}
      {isModalOpen && (
        <AddFiles
          companyInfo={companyInfo}
          getFiles={getFiles}
          document_labels={document_labels}
          file_type_mapping={file_type_mapping}
          updateCompanyData={updateCompanyData}
          closeModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default DataRoomComponent;
