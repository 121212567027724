/* (2019-06-11) - This is the main component of the team section of the SIR.
It handles all the functions for adding and editing judges. It also maps through
the judges and displays them.
*/
import React, { Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import classnames from "classnames";
import "./team.scss";
import Comment from "./../comment/Comment";
import Grid from "@material-ui/core/Grid";
import default_pic from "../../../../img/default-avatar.png";
import default_background from "../../../../img/bg.png";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../../../boilerplate/list.json";

import { Button, FormControl, FormLabel } from "react-bootstrap";
import { individual_user_type_list } from "../../../../boilerplate/list.json";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../../../App";

import SortBy from "../../../components/explore_sort_filter";
import ThreeDotsIcon from "../../../../newsfeed/icons/ThreeDotsIcon";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import ArgNav from "../argNav";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class Page_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Page {...this.props} {...this.context} />;
  }
}
class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      companies: [],
      selected_company_id: "",
      showCreateJudge: false,
      selected_company: "",
      showOpenReview: false,
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      sub_vertical: "",
      user_type: "Judge",
      success_msg: "Team member has succesfully been added!",
      showSuccessMsg: false,
      selected_judge_id: "",
      judges: [],

      show_err: false,
      err_msg: "",
      signup_message: "",
      pending_judges: [],
      judges_with_reviews: [],
    };
  }

  componentDidMount() {
    axios
      .get(
        `fetch_companies_opportunity_applied?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          companies: response.data.full_list,
        });
      });
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
        });
      });
    axios
      .get(
        `fetch_judges_for_opportunity_simple?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          judges_with_reviews: response.data.opp_judges,
        });
      });
    this.fetch_pending_judge();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.judges !== this.props.judges) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  fetch_pending_judge = () => {
    axios
      .get(
        `fetch_pending_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        console.log("fetch_pending_judges_for_opportunity");
        console.log(response);
        this.setState({
          pending_judges: response.data.pending_judges,
        });
      });
  };

  getAvatarUrl = (array, file_type) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_background;
      }
    }
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      showCreateJudge: !this.state.showCreateJudge,
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
      showSuccessMsg: false,
    });
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/create_user_judge", {
        email: this.state.email,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        password: this.state.password,
        sub_vertical: this.state.sub_vertical,
        status: "pending",
      })
      .then((response) => {
        if (response.data.status === "success") {
          this.props.persistUser(response.data.data);
          axios
            .post("/create_judge_opportunity_affiliation", {
              user: response.data.data._id,
              opportunity: this.state.url.query.opportunity_id,
            })
            .then((response) => {});
        } else if (response.data.status === "existing user") {
          this.setState({
            show_err: true,
            err_msg: "That email address is already registered to a user.",
            showSuccessMsg: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAdminInputSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `/admin_create_judge?judges_get_email=${this.state.judges_get_email}`,
        {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          password: this.state.password,
          email: this.state.email,
          sub_vertical: this.state.sub_vertical,
        },
      )
      .then((response) => {
        if (response.data.status === "success") {
          const newJudge = {
            _id: response.data.data._id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            sub_vertical: this.state.sub_vertical,
            status: "approved",
            files: [],
          };

          this.setState((prevState) => ({
            judges: [...prevState.judges, newJudge],
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            sub_vertical: "",
            showSuccessMsg: true,
            show_err: false,
          }));

          axios
            .post("/create_judge_opportunity_affiliation", {
              user: response.data.data._id,
              opportunity: this.state.url.query.opportunity_id,
              status: "approved",
            })
            .then(this.props.refresh_judges());
        } else if (response.data.status === "existing user") {
          this.setState({
            show_err: true,
            err_msg: "That email address is already registered to a user.",
            showSuccessMsg: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  showEditJudge = (judge_id) => {
    if (judge_id !== this.state.selected_judge_id) {
      this.setState({
        selected_judge_id: judge_id,
      });
    } else {
      this.setState({
        selected_judge_id: "",
      });
    }
  };
  handleEditJudge = (e, judge_id) => {
    e.preventDefault();
    axios
      .post(`/edit_judge_for_opportunity`, {
        judge_id: judge_id,
        sub_vertical: this.state.sub_vertical,
      })
      .then((response) => {
        this.setState({
          selected_judge_id: "",
        });
        this.props.refresh_judges();
      });
  };
  handleDeleteJudge = (e, judge_id) => {
    e.preventDefault();
    axios
      .post(
        `/delete_judge_from_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
        {
          judge_id: judge_id,
        },
      )
      .then((response) => {
        this.props.refresh_judges();
      });
  };

  request_access = () => {
    console.log("access requested");
    axios
      .post(
        `/create_pending_judge?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        console.log("access responded", response);
        if (response.data.status === "success") {
          this.setState({
            signup_message: "Thank you for applying.",
          });
        } else if (response.data.status === "already submitted") {
          this.setState({
            signup_message:
              "You have already applied. Please reach out to the organizer for approval.",
          });
        }
      });
  };

  accept_judge = (e, judge) => {
    e.preventDefault();
    axios
      .post(
        `/approve_judge?opportunity_id=${this.state.url.query.opportunity_id}`,
        { judge: judge },
      )
      .then((response) => {
        this.fetch_pending_judge();
        this.props.refresh_judges();
      });
  };
  decline_judge = (e, judge) => {
    e.preventDefault();
    axios
      .post(
        `/decline_judge?opportunity_id=${this.state.url.query.opportunity_id}`,
        { judge: judge },
      )
      .then((response) => {
        this.fetch_pending_judge();
        this.props.refresh_judges();
      });
  };

  render() {
    return (
      <div>
        <ArgNav
          opportunity_name={this.state.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div className="__content m-2">
          <div className="__pb-page py-2">
            <div className="__application-room-team">
              {this.props.loaded ? (
                <Fragment>
                  <div className="__submission-page __header">
                    <h3 className="__title">Team</h3>
                    {(this.props.opportunity_owner._id ===
                      this.props.user._id ||
                      this.props.user.email === "ash@startupfuel.com") && (
                      <Button
                        className="add-company-button btn btn-md float-right"
                        variant="primary"
                        onClick={this.handleToggle}
                      >
                        &#43; Add Team Member
                      </Button>
                    )}
                  </div>
                  {(this.props.opportunity_owner._id === this.props.user._id ||
                    this.props.user.email === "ash@startupfuel.com") &&
                    this.state.pending_judges != undefined &&
                    this.state.pending_judges.length > 0 && (
                      <div style={{ marginLeft: "15px" }}>
                        <h1> Pending Team Members</h1>
                        {this.state.pending_judges.map((judge, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            xl={3}
                            justify="flex-start"
                            alignItems="flex-start"
                          >
                            <div
                              class="container-team"
                              style={{
                                border: "007bff",
                                borderStyle: "ridge",
                                textAlign: "center",
                                width: "unset",
                                padding: "0px 0px 20px 0px",
                                margin: "0px 8px 78px 8px",
                              }}
                            >
                              <div class="avatar-flip">
                                <img
                                  src={this.props.getAvatarUrl(
                                    judge.files,
                                    "user avatar",
                                  )}
                                  height="aut0"
                                  width="100%"
                                ></img>
                              </div>
                              <h3 style={{ marginTop: "10px" }}>
                                {judge.first_name} {judge.last_name}
                              </h3>
                              {judge.company !== undefined &&
                                judge.company !== null && (
                                  <h4>{judge.company.company_name}</h4>
                                )}
                              <div style={{ display: "inline-flex" }}>
                                <Button
                                  onClick={(event) =>
                                    this.accept_judge(event, judge._id)
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  onClick={(event) =>
                                    this.decline_judge(event, judge._id)
                                  }
                                  variant="danger"
                                >
                                  Decline
                                </Button>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </div>
                    )}

                  <div style={{ maxWidth: "unset" }}>
                    <div>
                      <div>
                        {(this.props.opportunity_owner._id ===
                          this.props.user._id ||
                          this.props.user.email === "ash@startupfuel.com") &&
                          this.state.showCreateJudge && (
                            <div className="main-login main-center form-horizontal">
                              {this.state.judges_can_register && (
                                <div>
                                  <h3 className="title">Registration Link</h3>
                                  <br />
                                  <p style={{ textAlign: "center" }}>
                                    {this.state.url.origin}
                                    /applicant_room_team?opportunity_id=
                                    {this.state.url.query.opportunity_id}
                                  </p>
                                  <hr className="mb-5" />
                                </div>
                              )}
                              <div className="form-gorup">
                                <h3 className="title">
                                  Create Account for Team Member
                                </h3>
                                <br />
                                <FormLabel className="control-label">
                                  First Name
                                </FormLabel>
                                <FormControl
                                  type="text"
                                  value={this.state.first_name}
                                  placeholder="First Name"
                                  name="first_name"
                                  onChange={this.handleChange}
                                  className="form-control mb-3"
                                />
                              </div>

                              <FormLabel className="control-label">
                                Last Name
                              </FormLabel>
                              <FormControl
                                type="text"
                                value={this.state.last_name}
                                placeholder="Last Name"
                                name="last_name"
                                onChange={this.handleChange}
                                className="form-control mb-3"
                              />

                              <FormLabel className="control-label">
                                Email
                              </FormLabel>
                              <FormControl
                                type="email"
                                value={this.state.email}
                                placeholder="User Email"
                                name="email"
                                onChange={this.handleChange}
                                className="form-control mb-3"
                              />

                              <FormLabel className="control-label">
                                Password
                              </FormLabel>
                              <FormControl
                                type="password"
                                value={this.state.password}
                                placeholder="User Password"
                                name="password"
                                onChange={this.handleChange}
                                className="form-control mb-3"
                              />
                              {this.props.opportunity_data
                                .has_judge_specialty && (
                                <>
                                  <FormLabel className="control-label">
                                    Team Role
                                  </FormLabel>
                                  <Select
                                    value={this.state.sub_vertical}
                                    onChange={this.handleChange}
                                    input={<Input id="select-multiple-chip" />}
                                    name="sub_vertical"
                                    style={{ display: "inherit" }}
                                    renderValue={(selected) => (
                                      <div>
                                        <MenuItem
                                          key={this.state.sub_vertical}
                                          value={this.state.sub_vertical}
                                        >
                                          {this.state.sub_vertical}
                                        </MenuItem>
                                      </div>
                                    )}
                                    MenuProps={MenuProps}
                                  >
                                    {vertical_list.map((name) => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              )}
                              <br />
                              <Button
                                type="submit"
                                onClick={this.handleAdminInputSubmit}
                                className="btn btn-primary btn-lg btn-block login-button my-1"
                              >
                                Submit
                              </Button>
                              {this.state.showSuccessMsg && (
                                <span
                                  style={{
                                    color: "green",
                                    display: "block",
                                    textAlign: "center",
                                  }}
                                >
                                  {this.state.success_msg}
                                </span>
                              )}
                              {this.state.show_err && (
                                <span
                                  style={{
                                    color: "red",
                                    display: "block",
                                    textAlign: "center",
                                  }}
                                >
                                  {this.state.err_msg}
                                </span>
                              )}
                            </div>
                          )}
                        {this.props.isLoggedIn === false &&
                          this.state.judges_can_register === true && (
                            <div class="main-login main-center form-horizontal mb-5">
                              <div class="panel-title text-center">
                                <h1 class="title">Register</h1>
                                <hr className="mb-5" />
                              </div>
                              <div className="form-gorup">
                                <br />
                                <FormLabel className="control-label">
                                  First Name
                                </FormLabel>
                                <FormControl
                                  type="text"
                                  value={this.state.first_name}
                                  placeholder="First Name"
                                  name="first_name"
                                  onChange={this.handleChange}
                                  className="form-control mb-3"
                                />
                              </div>

                              <FormLabel className="control-label">
                                Last Name
                              </FormLabel>
                              <FormControl
                                type="text"
                                value={this.state.last_name}
                                placeholder="Last Name"
                                name="last_name"
                                onChange={this.handleChange}
                                className="form-control mb-3"
                              />

                              <FormLabel className="control-label">
                                Email
                              </FormLabel>
                              <FormControl
                                type="email"
                                value={this.state.email}
                                placeholder="User Email"
                                name="email"
                                onChange={this.handleChange}
                                className="form-control mb-3"
                              />

                              <FormLabel className="control-label">
                                Password
                              </FormLabel>
                              <FormControl
                                type="password"
                                value={this.state.password}
                                placeholder="User Password"
                                name="password"
                                onChange={this.handleChange}
                                className="form-control mb-3"
                              />
                              {this.props.opportunity_data !== undefined &&
                                this.props.opportunity_data
                                  .has_judge_specialty && (
                                  <>
                                    <FormLabel className="control-label">
                                      Team Role
                                    </FormLabel>
                                    <Select
                                      value={this.state.sub_vertical}
                                      onChange={this.handleChange}
                                      input={
                                        <Input id="select-multiple-chip" />
                                      }
                                      name="sub_vertical"
                                      style={{ display: "inherit" }}
                                      renderValue={(selected) => (
                                        <div>
                                          <MenuItem
                                            key={this.state.sub_vertical}
                                            value={this.state.sub_vertical}
                                          >
                                            {this.state.sub_vertical}
                                          </MenuItem>
                                        </div>
                                      )}
                                      MenuProps={MenuProps}
                                    >
                                      {vertical_list.map((name) => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                )}

                              <Button
                                type="submit"
                                onClick={this.handleInputSubmit}
                                className="btn btn-primary btn-lg btn-block login-button my-1"
                              >
                                Submit
                              </Button>

                              <div className="text-center mt-3">
                                <a href="/sign_in">Log-in</a>
                              </div>
                              {this.state.show_err && (
                                <span
                                  style={{
                                    color: "red",
                                    display: "block",
                                    textAlign: "center",
                                  }}
                                >
                                  {this.state.err_msg}
                                </span>
                              )}
                            </div>
                          )}
                        {/* this.props.isLoggedIn === true &&
                      !this.props.hasAccess &&
                      this.props.opportunity_owner.user !==
                        this.props.user._id && (
                        <>
                          <Card
                            className="simpleExploreCard"
                            style={{
                              width: "400px",
                              margin: "auto",
                              textAlign: "center",
                            }}
                          >
                            <CardHeader
                              title={"Want to join the team?"}
                              style={{ margin: "auto" }}
                              action={
                                <CardActions
                                  disableSpacing
                                  style={{ paddingLeft: 0 }}
                                ></CardActions>
                              }
                            />
                            <CardContent>
                              <div className="cardContent">
                                {
                                  "Must be approved in order to start reviewing the companies."
                                }
                              </div>
                            </CardContent>
                            <CardActions
                              className="createOppCardAction"
                              style={{ display: "inline-block", margin: "auto" }}
                            >
                              <Button
                                size="small"
                                disable={
                                  this.state.signup_message.length > 0
                                    ? true
                                    : false
                                }
                                onClick={this.request_access}
                                color="primary"
                                className={true ? "UbaidUnfollow" : "ubaidFollow"}
                              >
                                Request Access
                              </Button>
                              <p>{this.state.signup_message}</p>
                            </CardActions>
                          </Card>
                        </>
                      ) */}

                        {this.props.access_ids !== undefined &&
                          this.props.access_ids.includes(
                            this.props.user._id,
                          ) && (
                            <div className="__application-room-profile-card-grid ">
                              {this.state.judges.map((judge, index) => (
                                <div className="__team-member">
                                  <div className="__team-member-header">
                                    {(this.props.opportunity_owner._id ===
                                      this.props.user._id ||
                                      this.props.user._id === judge._id) &&
                                      this.props.opportunity_data !==
                                        undefined &&
                                      this.props.opportunity_data
                                        .has_judge_specialty && (
                                        <i
                                          onClick={() =>
                                            this.showEditJudge(judge._id)
                                          }
                                          className="material-icons"
                                        >
                                          edit
                                        </i>
                                      )}
                                    <div className="__team-member-avatar">
                                      <img
                                        src={this.props.getAvatarUrl(
                                          judge.files,
                                          "user avatar",
                                        )}
                                      ></img>
                                    </div>
                                    {this.props.opportunity_owner._id ===
                                      this.props.user._id && (
                                      <i
                                        onClick={(event) =>
                                          this.handleDeleteJudge(
                                            event,
                                            judge._id,
                                          )
                                        }
                                        className="material-icons"
                                      >
                                        delete
                                      </i>
                                    )}
                                  </div>
                                  <div className="__team-member-main">
                                    <h3>
                                      {judge.first_name} {judge.last_name}
                                    </h3>
                                    {judge.company !== undefined &&
                                      judge.company !== null && (
                                        <h4>{judge.company.company_name}</h4>
                                      )}
                                    {this.state.selected_judge_id !==
                                    judge._id ? (
                                      <>
                                        {this.props.opportunity_data !==
                                          undefined &&
                                          this.props.opportunity_data
                                            .has_judge_specialty && (
                                            <h5 style={{ fontStyle: "italic" }}>
                                              {judge.sub_vertical}
                                            </h5>
                                          )}
                                      </>
                                    ) : (
                                      <div>
                                        {this.props.opportunity_data !==
                                          undefined &&
                                          this.props.opportunity_data
                                            .has_judge_specialty && (
                                            <>
                                              <FormLabel className="control-label">
                                                Team Role
                                              </FormLabel>
                                              <Select
                                                value={this.state.sub_vertical}
                                                onChange={this.handleChange}
                                                input={
                                                  <Input id="select-multiple-chip" />
                                                }
                                                name="sub_vertical"
                                                renderValue={(selected) => (
                                                  <div>
                                                    <MenuItem
                                                      key={
                                                        this.state.sub_vertical
                                                      }
                                                      value={
                                                        this.state.sub_vertical
                                                      }
                                                    >
                                                      {this.state.sub_vertical}
                                                    </MenuItem>
                                                  </div>
                                                )}
                                                MenuProps={MenuProps}
                                              >
                                                {vertical_list.map((name) => (
                                                  <MenuItem
                                                    key={name}
                                                    value={name}
                                                  >
                                                    {name}
                                                  </MenuItem>
                                                ))}
                                              </Select>

                                              <Button
                                                variant="contained"
                                                onClick={(event) =>
                                                  this.handleEditJudge(
                                                    event,
                                                    judge._id,
                                                  )
                                                }
                                              >
                                                Save
                                              </Button>
                                            </>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="ubaidSubmissionPageWrap">
                  <div className={classnames("SocialLoadingIcon")}>
                    <ThreeDotsIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Page_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Page_Container;
