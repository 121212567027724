import React, { useState, useEffect } from "react";
import axios from "axios";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import "./reports.css"; // Assuming you're using a similar stylesheet

export default function Metrics({
  updateCompanyData,
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
}) {
  const [kpiGenerations, setKpiGenerations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedKpis, setExpandedKpis] = useState({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.metrics) {
      // Ensure new data comes first and expand all initially
      setKpiGenerations([...companyInfo.aiGenerations.metrics]);
      setExpandedKpis(
        companyInfo.aiGenerations.metrics.reduce((acc, _, index) => {
          acc[index] = true;
          return acc;
        }, {}),
      );
    }
  }, [companyInfo]);

  const fetchKpiData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/fetch_kpi_data?company_id=${companyInfo.applicant_company}&opportunity_id=${opportunityInfo._id}`,
        { applicationInfo: applicationObject },
      );

      if (response.status === 200 && response.data.metrics) {
        // Ensure that `fetchedKpiData` is always treated as an array
        let fetchedKpiData = Array.isArray(response.data.metrics)
          ? response.data.metrics
          : [response.data.metrics];

        handleCreditChange(-1);

        // Add the new KPI data on top of the existing data
        setKpiGenerations((prevData) => {
          const uniqueNewKpiData = fetchedKpiData.filter(
            (newKpi) =>
              !prevData.some(
                (existingKpi) => existingKpi.created_on === newKpi.created_on,
              ),
          );

          // Set expanded state for the new KPIs as true by default
          setExpandedKpis((prevExpanded) => {
            const newExpandedState = { ...prevExpanded };
            uniqueNewKpiData.forEach((_, index) => {
              newExpandedState[index] = true;
            });
            return newExpandedState;
          });

          return [...uniqueNewKpiData, ...prevData];
        });

        // Update the company object with the new KPI data
        updateCompanyData(companyInfo.applicant_company, {
          aiGenerations: {
            ...companyInfo.aiGenerations,
            metrics: [
              ...fetchedKpiData,
              ...(companyInfo.aiGenerations.metrics || []),
            ],
          },
        });
      }
    } catch (error) {
      console.error("Error fetching KPI data:", error);
      setError(
        error.message || "An unexpected error occurred while fetching metrics.",
      );
    } finally {
      setLoading(false);
    }
  };

  const toggleKpiExpansion = (index) => {
    setExpandedKpis((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderKpiDetails = (kpiObj, index) => {
    if (!kpiObj || kpiObj.length === 0) return null;

    return (
      <div className="report-container" key={index}>
        <div
          className="overall-summary"
          onClick={() => toggleKpiExpansion(index)}
        >
          <h2 className="overall-score">
            Metrics Report {kpiGenerations.length - index}:{" "}
            {expandedKpis[index] ? <ExpandLess /> : <ExpandMore />}
          </h2>
          <div className="score-details-right">
            <p className="created-on">
              Date Created: {new Date(kpiObj.created_on).toLocaleDateString()}
            </p>
          </div>
        </div>

        {expandedKpis[index] && (
          <table className="table-fixed w-100">
            <thead>
              <tr className="border-bottom border-gray border-t">
                <th className="py-4 font-bold text-sm">Title</th>
                <th className="py-4 font-bold text-sm">Data</th>
                <th className="py-4 font-bold text-sm">Description</th>
              </tr>
            </thead>
            <tbody>
              {kpiObj.metrics.map((kpi, idx) => (
                <tr className="border-bottom border-gray border-t" key={idx}>
                  <td className="py-4 text-sm">{kpi.title}</td>
                  <td className="py-4 text-sm">{kpi.data}</td>
                  <td className="py-4 text-sm">{kpi.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Metrics</h4>
      <p className="text-grey text-sm">Displays the metrics of the company.</p>
      <div className="mr-4">
        <button
          className="summarize-button"
          onClick={fetchKpiData}
          disabled={loading}
        >
          {loading ? "Generating Metrics..." : "Generate Metrics"}
        </button>
      </div>

      {loading && (
        <p className="text-gray-500 text-sm mt-2">
          Generating metrics... this may take a minute.
        </p>
      )}

      {!loading && error && (
        <p className="text-red-500 text-sm mt-2">Error: {error}</p>
      )}

      {!loading && !error && kpiGenerations.length === 0 && (
        <p className="text-red-500 text-sm mt-2">No metrics found.</p>
      )}

      {!loading && !error && kpiGenerations.length > 0 && (
        <div>
          {kpiGenerations.map((kpiObj, index) =>
            renderKpiDetails(kpiObj, index),
          )}
        </div>
      )}
    </div>
  );
}
