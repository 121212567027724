import React, { useState } from "react";
import DealInfoComponent from "./dealInfoComponent";
import Submenu from "../submenu";
import ApplicationInfoComponent from "./applicationInfoComponent";
import DataRoomComponent from "./dataRoomComponent";
import ChatLayout from "../reviewsCardContainer";

const DealInfoMain = ({
  companyInfo,
  applicationObject,
  files,
  deleteFile,
  getFiles,
  updateCompanyData,
  opportunity_data,
  handleCreditChange,
  user,
}) => {
  const menuItems = ["Data Room", "Company Info", "Deal Info"];
  const [activeItem, setActiveItem] = useState("Data Room");

  const renderActiveComponent = () => {
    switch (activeItem) {
      case "Data Room":
        return (
          <DataRoomComponent
            files={files}
            deleteFile={deleteFile}
            companyInfo={companyInfo}
            getFiles={getFiles}
            updateCompanyData={updateCompanyData}
          />
        );
      case "Company Info":
        return <DealInfoComponent companyInfo={companyInfo} />;
      case "Deal Info":
        return (
          <ApplicationInfoComponent applicationObject={applicationObject} />
        );
      default:
        return null;
    }
  };

  return (
    <ChatLayout
      companyInfo={companyInfo}
      opportunity_data={opportunity_data}
      updateCompanyData={updateCompanyData}
      applicationObject={applicationObject}
      handleCreditChange={handleCreditChange}
      user={user}
    >
      <div>
        <Submenu
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          menuItems={menuItems}
        />
        <div className="deal-info">{renderActiveComponent()}</div>
      </div>
    </ChatLayout>
  );
};

export default DealInfoMain;
