import React, { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

export default function DataIntegrityScore({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
}) {
  const [dataIntegrityScores, setDataIntegrityScores] = useState([]);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [dataIntegrityScoreError, setDataIntegrityScoreError] = useState("");
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});
  const [expandedScores, setExpandedScores] = useState({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.dataintegrityscores) {
      setDataIntegrityScores(
        [...companyInfo.aiGenerations.dataintegrityscores].reverse(),
      );
    }
  }, [companyInfo]);

  const handleDataIntegrityScore = async () => {
    const { pitchdeckUrl } = companyInfo;
    const companyId = companyInfo.applicant_company;
    const opportunityId = opportunityInfo._id;

    // Check if both application data and pitch deck URL are missing
    if (!applicationObject && !pitchdeckUrl) {
      setDataIntegrityScoreError(
        "Application Info and Pitchdeck URL are missing.",
      );
      return;
    }

    // Check if enough credits are available
    if (opportunityInfo.credits < 1) {
      setDataIntegrityScoreError(
        "You do not have enough credits to generate a Data Integrity score.",
      );
      return;
    }

    setIsSummarizing(true);
    setDataIntegrityScoreError(""); // Clear any previous errors

    try {
      // Prepare the request body with valid answers and pitchdeck URL
      const requestBody = {};

      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      if (pitchdeckUrl) {
        requestBody.pitchdeckUrl = pitchdeckUrl;
      }

      // Make the fetch request
      const response = await fetch(
        `/fetch_data_integrity_score?company_id=${companyId}&opportunity_id=${opportunityId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        },
      );

      // Check for specific 404 status and handle it
      if (response.status === 404) {
        setDataIntegrityScoreError(
          "Pitch deck or necessary information is missing. Please upload a pitch deck.",
        );
        throw new Error("Pitch deck or necessary information is missing.");
      }

      // Handle other unsuccessful responses
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Parse the response data
      const data = await response.json();
      console.log("Response from server:", data);

      // Deduct a credit after successful response
      handleCreditChange(-1);

      // Construct the new Data Integrity score
      const newDataIntegrityScore = {
        dataintegrityscore: data.analysis.dataintegrityscore,
        created_on: data.analysis.created_on,
        credits_used: data.analysis.credits_used,
      };

      // Update company data with the new Data Integrity score
      const updatedScores = [
        newDataIntegrityScore,
        ...(companyInfo.aiGenerations?.dataintegrityscores || []), // Preserve previous scores
      ];

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          dataintegrityscores: updatedScores,
        },
      };

      updateCompanyData(companyId, updates);

      // Reverse the order when setting the state for display
      setDataIntegrityScores([...updatedScores].reverse());
      setIsNewlyGenerated(true);
      setAnimationComplete({});
      setExpandedScores((prev) => ({
        ...prev,
        0: true, // Expand the most recent score, which is now at index 0
      }));
    } catch (error) {
      console.error("Error fetching Data Integrity score:", error);
      setDataIntegrityScoreError("Error fetching Data Integrity score.");
    } finally {
      setIsSummarizing(false); // Reset the loading state
    }
  };

  const handleAnimationComplete = (category, scoreIndex) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [`${scoreIndex}-${category}`]: true,
    }));

    const allComplete = Object.values(animationComplete).every(
      (value) => value,
    );
    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, category, scoreIndex) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(category, scoreIndex)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  const toggleScoreExpansion = (index) => {
    setExpandedScores((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderScoreDetails = (score, index) => {
    if (
      !score ||
      !score.dataintegrityscore ||
      Object.keys(score.dataintegrityscore).length === 0
    )
      return null;

    const overallCategory = "Overall";
    const categories = Object.keys(score.dataintegrityscore).filter(
      (category) => category.toLowerCase() !== "overall" && category !== "_id",
    );

    return (
      <div className="report-container" key={index}>
        <div
          className="overall-summary"
          onClick={() => toggleScoreExpansion(index)}
        >
          <h2 className="overall-score">
            Data Integrity Score {dataIntegrityScores.length - index}:{" "}
            <span className="overall-score">
              {score.dataintegrityscore[overallCategory]?.Score
                ? `${Math.round(
                    score.dataintegrityscore[overallCategory].Score,
                  )}%`
                : "N/A"}
            </span>
            {expandedScores[index] ? <ExpandLess /> : <ExpandMore />}
          </h2>
          <div className="score-details-right">
            <p className="credits-used">Credits Used: {score.credits_used}</p>
            <p className="created-on">
              Date Created: {new Date(score.created_on).toLocaleDateString()}
            </p>
          </div>
        </div>

        {expandedScores[index] && (
          <>
            <div className="overall-score-container">
              <div
                className="overall-score-bar"
                style={{
                  width: `${score.dataintegrityscore[overallCategory]?.Score || 0}%`,
                }}
              ></div>
            </div>
            <p className="summary-text">
              {isNewlyGenerated && index === 0
                ? renderTypingAnimation(
                    score.dataintegrityscore[overallCategory]?.Reason ||
                      "No overall summary available.",
                    "overall",
                    index,
                  )
                : score.dataintegrityscore[overallCategory]?.Reason ||
                  "No overall summary available."}
            </p>

            {categories.map((category, catIndex) => (
              <div key={catIndex} className="category-item">
                <h3 className="category-title">
                  {category}:{" "}
                  {score.dataintegrityscore[category].Score
                    ? `${Math.round(score.dataintegrityscore[category].Score)}%`
                    : "N/A"}
                </h3>
                <div className="score-container">
                  <div
                    className="score-bar"
                    style={{
                      width: `${score.dataintegrityscore[category].Score || 0}%`,
                    }}
                  ></div>
                </div>
                <p className="category-reason">
                  {isNewlyGenerated && index === 0
                    ? renderTypingAnimation(
                        score.dataintegrityscore[category].Reason ||
                          "No reason provided.",
                        category,
                        index,
                      )
                    : score.dataintegrityscore[category].Reason ||
                      "No reason provided."}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Data Integrity Score</h4>
      <p className="text-grey text-sm">
        Generate a Data Integrity Score using the patent-pending StartupFuel
        Scoring DiligenceGPT Model.
      </p>
      <div className="flex justify-between">
        <div className="mr-4">
          <button
            onClick={handleDataIntegrityScore}
            disabled={isSummarizing}
            className="summarize-button"
          >
            {isSummarizing ? "Generating..." : "Generate Data Integrity Score"}
          </button>
        </div>
      </div>
      {dataIntegrityScoreError && (
        <p className="error">{dataIntegrityScoreError}</p>
      )}
      {dataIntegrityScores.map((score, index) =>
        renderScoreDetails(score, index),
      )}
    </div>
  );
}
