import React, { useState, useEffect } from "react";
import "./reports.css";
import { TypeAnimation } from "react-type-animation";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

export default function Summary({
  companyInfo,
  applicationObject,
  updateCompanyData,
  handleCreditChange,
  opportunityInfo,
}) {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});
  const [expandedSummaries, setExpandedSummaries] = useState({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.summaries) {
      setSummaries([...companyInfo.aiGenerations.summaries].reverse());
    }
  }, [companyInfo]);

  const sections = ["Idea", "Tech", "Team", "Marketing", "Traction"];

  const handleSummarize = async () => {
    const companyId = companyInfo.applicant_company;
    const opportunityId = opportunityInfo._id;

    // Check if application data exists
    if (!applicationObject) {
      setError("Application Info is missing.");
      return;
    }

    // Check if enough credits are available
    if (opportunityInfo.credits < 1) {
      setError("You do not have enough credits to generate a deal brief.");
      return;
    }

    // Set loading state
    setLoading(true);
    setError(null);

    try {
      // Prepare the request body with valid answers
      const requestBody = {};
      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      // Make the fetch request
      const response = await fetch(
        `/fetch_company_summary?company_id=${companyId}&opportunity_id=${opportunityId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        },
      );

      // Handle unsuccessful response
      if (!response.ok) {
        if (response.status === 404) {
          setError(
            "Pitch deck or necessary information is missing. Please upload a pitch deck.",
          );
        } else {
          setError("Failed to fetch deal brief.");
        }
        throw new Error("Failed to fetch deal brief.");
      }

      // Parse the response data
      const data = await response.json();
      console.log("Response from server:", data);

      // Handle credit deduction
      handleCreditChange(-1);

      // Construct the new summary
      const newSummary = {
        summary: data.summary.summary,
        created_on: data.summary.created_on,
        credits_used: data.summary.credits_used,
      };

      // Update company data with new summary
      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          summaries: [
            newSummary,
            ...(companyInfo.aiGenerations?.summaries || []), // Keep previous summaries
          ],
        },
      };

      updateCompanyData(companyId, updates);

      // Update local state to reflect the new summary
      setSummaries((prevSummaries) => [newSummary, ...prevSummaries]); // Add new summary at the top
      setIsNewlyGenerated(true); // Indicate a new summary was generated
      setAnimationComplete({}); // Reset animation
      setExpandedSummaries((prev) => ({ ...prev, 0: true })); // Expand the most recent summary
    } catch (error) {
      console.error("Error fetching summary:", error);
      setError("Error fetching summary.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleAnimationComplete = (section, summaryIndex) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [`${summaryIndex}-${section}`]: true,
    }));

    const allComplete = Object.values({
      ...animationComplete,
      [`${summaryIndex}-${section}`]: true,
    }).every((value) => value);

    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, section, summaryIndex) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(section, summaryIndex)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  const toggleSummaryExpansion = (index) => {
    setExpandedSummaries((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderSummaryDetails = (summaryObj, index) => {
    if (!summaryObj || Object.keys(summaryObj.summary).length === 0)
      return null;

    return (
      <div className="report-container" key={index}>
        <div
          className="overall-summary"
          onClick={() => toggleSummaryExpansion(index)}
        >
          <h2 className="overall-score">
            Deal brief {summaries.length - index}:{" "}
            {expandedSummaries[index] ? <ExpandLess /> : <ExpandMore />}
          </h2>
          <div className="score-details-right">
            <p className="credits-used">
              Credits Used: {summaryObj.credits_used}
            </p>
            <p className="created-on">
              Date Created:{" "}
              {new Date(summaryObj.created_on).toLocaleDateString()}
            </p>
          </div>
        </div>

        {expandedSummaries[index] && (
          <>
            {sections.map((section) => (
              <div key={section} className="category-item">
                <h3 className="category-title">{section}</h3>
                <p className="summary-text">
                  {isNewlyGenerated && index === 0
                    ? renderTypingAnimation(
                        summaryObj.summary[section] ||
                          `No deal brief available for ${section}`,
                        section,
                        index,
                      )
                    : summaryObj.summary[section] ||
                      `No deal brief available for ${section}`}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Deal Summary</h4>
      <p className="text-grey text-sm">
        Generate a Deal Brief of the Deal using the patent pending Deal
        Summarization DiligenceGPT Model.
      </p>
      <button
        onClick={handleSummarize}
        disabled={loading}
        className="summarize-button"
        style={{ marginBottom: "20px" }}
      >
        {loading ? "Generating..." : "Generate new deal brief"}
      </button>

      {error && <p className="error-text">{error}</p>}

      {summaries.map((summaryObj, index) =>
        renderSummaryDetails(summaryObj, index),
      )}
    </div>
  );
}
